body {
  margin: 0;
  font-family: "Satoshi";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fdfdfd;
  pointer-events: "none";
  /* mobile viewport bug fix */
  min-height: -moz-available;
  min-height: -webkit-fill-available;
  min-height: fill-available;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Satoshi";
  src: local("Satoshi"), url(./fonts/Satoshi-Variable.ttf) format("opentype");
}
