body {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  font-family: "Satoshi";
}

.img1 {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.br {
  border-radius: 6px;
}

.br2 {
  border: 1px solid !important;
}

a {
  text-decoration: none;
  color: #0000ee;
}
a :visited {
  color: #551a8b;
}

.FooterLink {
  color: #0000ee;
  cursor: pointer;
}

.swiper-slide,
.swiper-slide-active {
  width: 200px !important;
}

.filter {
  filter: invert(1);
}

.ball {
  width: 20px;
  height: 20px;
  position: fixed;
  top: 0;
  left: 0;
  border: 3px solid #0092ff;
  border-radius: 50%;
  pointer-events: none;
  z-index: 24124124;
}

.t25o0 {
  transform: translateY(25px);
  opacity: 0;
}

.logoImg {
  filter: invert(100%) sepia(1%) saturate(1%) hue-rotate(0) brightness(1209%)
    contrast(300%);
  height: 42px;
}

.small {
  height: 32px !important;
}

.fill:hover,
.fill:focus {
  box-shadow: inset 0 0 0 2em var(--hover);
}

.pulse:hover,
.pulse:focus {
  -webkit-animation: pulse 1s;
  animation: pulse 1s;
  box-shadow: 0 0 0 2em transparent;
}

@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--hover);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 var(--hover);
  }
}
.close:hover,
.close:focus {
  box-shadow: inset -3.5em 0 0 0 var(--hover), inset 3.5em 0 0 0 var(--hover);
}

.raise:hover,
.raise:focus {
  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
  transform: translateY(-0.25em);
}

.up:hover,
.up:focus {
  box-shadow: inset 0 -3.25em 0 0 var(--hover);
}

.slide:hover,
.slide:focus {
  box-shadow: inset 6.5em 0 0 0 var(--hover);
}

.offset {
  padding: 0.75em 2.5em;
  box-shadow: 0.3em 0.3em 0 0 var(--color), inset 0.3em 0.3em 0 0 var(--color);
}
.offset:hover,
.offset:focus {
  box-shadow: 0 0 0 0 var(--hover), inset 6em 3.5em 0 0 var(--hover);
}

.offset {
  --color: #0092ff;
  --hover: #0092ff;
}

button {
  color: var(--color);
  transition: 0.25s;
}
button:hover,
button:focus {
  border-color: var(--hover);
  color: #fff;
}

button {
  background: none;
  border: 2px solid;
  font: inherit;
  line-height: 1;
  margin: 0.5em;
} /*# sourceMappingURL=styles.css.map */

@font-face {
  font-family: "Satoshi";
  src: local("Satoshi"), url(../fonts/Satoshi-Variable.ttf) format("opentype");
}
